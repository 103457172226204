// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/InputFields/TextField.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/InputFields/TextField.tsx");
  import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import React from 'react';
import { cn } from '~/lib/utils';
export const TextField = ({
  label,
  id,
  name,
  hasError = false,
  errorMessage = '',
  type = 'text',
  className = '',
  containerClass = '',
  inputClassName = '',
  required = false,
  caseValue = '',
  ...inputProps
}) => {
  const isInValid = hasError && errorMessage;
  return <div className={cn('mb-4 block relative', containerClass)}>
      <label htmlFor={id || name} className={cn('relative block rounded-md border shadow-sm', isInValid ? 'border-error-200 focus-within:border-error-600 focus-within:ring-1 focus-within:ring-error-600 ' : 'text-slate-700 border-slate-200 focus-within:border-slate-600 focus-within:ring-1 focus-within:ring-slate-600 ', className)}>
        <input id={id || name} type={type} name={name} className={cn('peer border-none bg-white focus:placeholder-transparent placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-0 text-base p-2.5 w-full rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-slate-100 read-only:bg-slate-100', inputClassName)} {...inputProps} style={{
        textTransform: caseValue
      }} />
        <span className={cn('pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs peer-placeholder-shown:opacity-0 peer-focus:opacity-100 peer-disabled:opacity-50 peer-disabled:cursor-not-allowed peer-disabled:bg-slate-100 peer-read-only:bg-slate-50', isInValid ? 'text-error-500' : 'text-slate-700')}>
          {label}
          {required && <span className='text-red-500 px-1 py-0.5 text-sm'>*</span>}
        </span>
        {/* </div> */}
      </label>
      {isInValid ? <span className='text-xs text-error-500 absolute bottom-[-18px]'>
          {errorMessage}
        </span> : ''}
    </div>;
};
_c = TextField;
var _c;
$RefreshReg$(_c, "TextField");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;